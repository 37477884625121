import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  EmailField,
  BooleanField,
  DateField,
  TextInput,
  DateInput,
  FilterButton,
  FilterForm,
  Title,
} from "react-admin";
import FluxField from "./listFluxField";
import { Stack } from "@mui/material";

const postFilters = [
  <TextInput label="Flux ID" source="FluxId" alwaysOn />,
  <TextInput label="Titre" source="title" alwaysOn />,
  <DateInput label="Du" source="startDate" alwaysOn />,
  <DateInput label="Jusqu'au" source="endDate" alwaysOn />,
  <TextInput label="Code postal" source="postalCode" alwaysOn />,
  <TextInput label="Ville" source="cityName" alwaysOn />,
  <TextInput label="Validé (oui/non)" source="validated" alwaysOn />,
];

const ListToolbar = () => (
  <Stack direction="row" justifyContent="space-between">
    <FilterForm filters={postFilters} />
  </Stack>
);
const DraftList = () => (
  <div>
    <List>
      <ListToolbar />
      <Datagrid rowClick="edit" isRowSelectable={false}>
        <TextField source="id" label="Id" sortable={false} />
        <TextField source="title" label="Titre" sortable={false} />
        <TextField source="cityName" label="Ville" sortable={false} />
        <FluxField source="FluxId" label="Flux ID" sortable={false} />
        <DateField source="startDate" label="Date début" sortable={false} />
        <DateField source="endDate" label="Date fin" sortable={false} />
        <BooleanField source="validated" label="Validé" sortable={false} />
      </Datagrid>
    </List>
  </div>
);

export default DraftList;
