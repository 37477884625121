import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  EmailField,
  BooleanField,
  DateField,
  TextInput,
  DateInput,
  FilterButton,
  FilterForm,
  Title,
} from "react-admin";
import ListBtnField from "./listBtnField";

const FluxList = () => (
  <div>
    <List>
      <Datagrid rowClick="edit" isRowSelectable={false}>
        <TextField source="id" label="Id" sortable={false} />
        <TextField source="other" label="Nom" sortable={false} />
        <DateField
          source="lastUpdate"
          label="Date dernier import"
          sortable={false}
        />
        <ListBtnField source="id" label="Action" sortable={false} />
      </Datagrid>
    </List>
  </div>
);

export default FluxList;
