import * as React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { useGetOne, useUpdate, Title, Button, BooleanInput } from "react-admin";
import {
  MenuItem,
  Stack,
  Card,
  TextField,
  Switch,
  FormControlLabel,
} from "@mui/material";
import moment from "moment";
import { useAgendaCategories, useEditions } from "../globalDataProvider";

const DraftEdit = () => {
  const { id } = useParams();
  const { handleSubmit, reset, control } = useForm();
  const { isLoading, data } = useGetOne(
    "drafts",
    { id },
    {
      onSuccess: (dataN) => {
        reset(dataN);
      },
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: false,
    },
    false
  );
  const [update, { isLoading: isSubmitting }] = useUpdate();
  const editions = useEditions();
  const aCategories = useAgendaCategories();
  const navigate = useNavigate();
  const onSubmit = (data) => {
    update(
      "drafts",
      { id, data },
      {
        onSuccess: () => {
          navigate("/drafts");
        },
      }
    );
  };
  if (isLoading) return null;
  if (data.title === undefined) return null;
  return (
    <div>
      <Title title="Edition" />
      <Card style={{ marginTop: 50, padding: 20 }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={5}>
            <Controller
              name="title"
              render={({ field }) => {
                if (field.value === undefined) return null;
                return <TextField label="Titre" value="" {...field} />;
              }}
              control={control}
            />

            {aCategories !== null && (
              <Controller
                name="AgendaCategoryId"
                render={({ field }) => {
                  if (field.value === undefined) return null;
                  return (
                    <TextField
                      select
                      label="Catégorie dans l'agenda"
                      {...field}
                    >
                      {aCategories.map((e) => (
                        <MenuItem value={e.id}>{e.name}</MenuItem>
                      ))}
                    </TextField>
                  );
                }}
                control={control}
              />
            )}
            <Controller
              name="description"
              render={({ field }) => {
                if (field.value === undefined) return null;
                return <TextField label="Description" multiline {...field} />;
              }}
              control={control}
            />
            <Controller
              name="infoSup"
              render={({ field }) => {
                if (field.value === undefined) return null;
                return (
                  <TextField label="Renseignements" multiline {...field} />
                );
              }}
              control={control}
            />

            <Card
              style={{
                marginTop: 20,
                padding: 20,
              }}
              variant="outlined"
            >
              <div
                style={{ marginTop: 0, marginBottom: 20, fontWeight: "bold" }}
              >
                Print
              </div>
              <Stack spacing={3}>
                <Controller
                  name="forPrint"
                  render={({ field }) => {
                    if (field.value === undefined) return null;
                    return (
                      <FormControlLabel
                        control={
                          <Switch
                            color="primary"
                            {...field}
                            checked={field.value}
                          />
                        }
                        label="Envoyer sur le print"
                        labelPlacement="end"
                        {...field}
                      />
                    );
                  }}
                  control={control}
                />
                <Controller
                  name="descriptionFormated"
                  render={({ field }) => {
                    if (field.value === undefined) return null;
                    return (
                      <TextField
                        label="Description Print"
                        {...field}
                        multiline
                        helperText={
                          field && field.value
                            ? `${field.value.length} caractères`
                            : ""
                        }
                      />
                    );
                  }}
                  control={control}
                />

                {editions !== null && (
                  <Controller
                    name="EditionId"
                    render={({ field }) => (
                      <TextField select label="Edition" {...field}>
                        {editions.map((e) => (
                          <MenuItem value={e.id}>{e.name}</MenuItem>
                        ))}
                      </TextField>
                    )}
                    control={control}
                  />
                )}
              </Stack>
            </Card>

            <Card style={{ marginTop: 20, padding: 20 }} variant="outlined">
              <div
                style={{ marginTop: 0, marginBottom: 20, fontWeight: "bold" }}
              >
                Ville
              </div>
              <Stack spacing={5}>
                <Controller
                  name="cityName"
                  render={({ field }) => {
                    if (field.value === undefined) return null;
                    return <TextField label="Ville" {...field} />;
                  }}
                  control={control}
                />
                <Controller
                  name="postalCode"
                  render={({ field }) => {
                    if (field.value === undefined) return null;
                    return <TextField label="Code postal" {...field} />;
                  }}
                  control={control}
                />
              </Stack>
            </Card>

            <Card style={{ marginTop: 20, padding: 20 }} variant="outlined">
              <div
                style={{ marginTop: 0, marginBottom: 20, fontWeight: "bold" }}
              >
                Image
              </div>
              <Stack spacing={5}>
                <Controller
                  name="image"
                  render={({ field }) => {
                    if (field.value === undefined) return null;
                    return (
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <TextField label="Lien" {...field} />
                        {field.value !== "" && (
                          <img
                            style={{
                              maxWidth: "200px",
                              maxHeight: 150,
                              marginTop: 15,
                              borderRadius: 3,
                            }}
                            src={field.value}
                            alt="preview"
                          />
                        )}
                      </div>
                    );
                  }}
                  control={control}
                />
              </Stack>
            </Card>
            <Controller
              name="validated"
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <Switch color="primary" {...field} checked={field.value} />
                  }
                  label="Validé"
                  labelPlacement="end"
                  {...field}
                />
              )}
              control={control}
            />
            <Button
              type="submit"
              variant="contained"
              disabled={isSubmitting}
              style={{ maxWidth: 200 }}
            >
              Enregistrer
            </Button>
            <Card style={{ marginTop: 20, padding: 20 }} variant="outlined">
              <div
                style={{ marginTop: 0, marginBottom: 20, fontWeight: "bold" }}
              >
                Dates
              </div>
              <Stack spacing={5}>
                {data &&
                  data.Dates &&
                  data.Dates.map((date) => {
                    return (
                      <div>
                        Du{" "}
                        {moment(date.startDate).format("DD/MM/YYYY à H:MM:ss")}{" "}
                        au {moment(date.endDate).format("DD/MM/YYYY à H:MM:ss")}
                      </div>
                    );
                  })}
              </Stack>
            </Card>
          </Stack>
        </form>
      </Card>
    </div>
  );
};

export default DraftEdit;
