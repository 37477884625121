// in src/App.js
import * as React from "react";
import { Admin, Resource, CustomRoutes } from "react-admin";
import DraftList from "./drafts/list";
import DraftEdit from "./drafts/edit";
import DraftAdd from "./drafts/add";
import authProvider from "./AuthProvider";
import dataProvider from "./dataProvider";
import frenchMessages from "ra-language-french";
import polyglotI18nProvider from "ra-i18n-polyglot";
import { Route } from "react-router-dom";
import { LayoutC } from "./Layout";
import Import from "./Import";
import Export from "./Export";
import Settings from "./Settings";
import FluxList from "./fluxs/list";

export const i18nProvider = polyglotI18nProvider(() => frenchMessages, "fr");
const App = () => {
  return (
    <Admin
      dataProvider={dataProvider}
      authProvider={authProvider}
      locale="fr"
      i18nProvider={i18nProvider}
      layout={LayoutC}
      title={"Admin JDP"}
    >
      <Resource
        name="drafts"
        list={DraftList}
        edit={DraftEdit}
        create={DraftAdd}
        options={{ label: "Events à valider" }}
      />
      <Resource
        name="fluxs"
        list={FluxList}
        options={{ label: "Import par flux" }}
      />
      <CustomRoutes>
        <Route path="/import" element={<Import />} />
        <Route path="/export" element={<Export />} />
        <Route path="/settings" element={<Settings />} />
      </CustomRoutes>
    </Admin>
  );
};
export default App;
