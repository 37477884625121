import * as React from "react";
import { DashboardMenuItem, Menu, MenuItemLink } from "react-admin";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import SimCardDownloadIcon from "@mui/icons-material/SimCardDownload";
import PublishIcon from "@mui/icons-material/Publish";
import SettingsIcon from "@mui/icons-material/Settings";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";

export const MenuC = (props) => (
  <Menu {...props}>
    {/*<DashboardMenuItem />*/}
    <MenuItemLink
      to="/drafts"
      primaryText="Events à valider"
      leftIcon={<EventAvailableIcon />}
    />
    <MenuItemLink
      to="/fluxs"
      primaryText="Import par flux"
      leftIcon={<CloudDownloadIcon />}
    />
    <MenuItemLink
      to="/import"
      primaryText="Import CSV"
      leftIcon={<SimCardDownloadIcon />}
    />
    <MenuItemLink
      to="/export"
      primaryText="Export sur WordPress"
      leftIcon={<PublishIcon />}
    />
    <MenuItemLink
      to="/settings"
      primaryText="Réglages"
      leftIcon={<SettingsIcon />}
    />
  </Menu>
);
