import * as React from "react";
import { Card, CardContent, Button } from "@mui/material";
import { Title } from "react-admin";
import config from "./config.json";
import { useForm } from "react-hook-form";

const Settings = () => {
  return (
    <Card style={{ marginTop: 30 }}>
      <Title title="Réglages" />
      <CardContent style={{ fontWeight: "bold" }}>
        <a href={"https://admin.jdp.lik.media"} target="_blank">
          Accéder au super admin
        </a>
      </CardContent>
    </Card>
  );
};

export default Settings;
