import * as React from "react";
import { useRecordContext, Button } from "react-admin";
import { useFluxs } from "../globalDataProvider";

const ListBtnField = ({ source }) => {
  const record = useRecordContext();
  return (
    <Button
      onClick={() => {
        window.open(
          `https://api.jdp.lik.media/public/import?id=${record[source]}`,
          "_blank"
        );
      }}
      variant="contained"
    >
      Relancer
    </Button>
  );
};

export default ListBtnField;
