import * as React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { useGetOne, useCreate, Title, Button, BooleanInput } from "react-admin";
import {
  MenuItem,
  Stack,
  Card,
  TextField,
  Switch,
  FormControlLabel,
} from "@mui/material";
import moment from "moment";
import { useAgendaCategories, useEditions } from "../globalDataProvider";

const DraftAdd = () => {
  const { id } = useParams();
  const { handleSubmit, reset, control } = useForm();
  const [create, { isLoading: isSubmitting }] = useCreate();
  const editions = useEditions();
  const aCategories = useAgendaCategories();
  const navigate = useNavigate();
  const onSubmit = (data) => {
    create(
      "drafts",
      { id, data },
      {
        onSuccess: () => {
          navigate("/drafts");
        },
      }
    );
  };
  return (
    <div>
      <Title title="Edition" />
      <Card style={{ marginTop: 50, padding: 20 }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={5}>
            <Controller
              name="title"
              render={({ field }) => {
                return <TextField label="Titre" value="" {...field} />;
              }}
              control={control}
            />

            {aCategories !== null && (
              <Controller
                name="AgendaCategoryId"
                render={({ field }) => {
                  return (
                    <TextField
                      select
                      label="Catégorie dans l'agenda"
                      {...field}
                    >
                      {aCategories.map((e) => (
                        <MenuItem value={e.id}>{e.name}</MenuItem>
                      ))}
                    </TextField>
                  );
                }}
                control={control}
              />
            )}
            <Controller
              name="description"
              render={({ field }) => {
                return <TextField label="Description" multiline {...field} />;
              }}
              control={control}
            />
            <Controller
              name="infoSup"
              render={({ field }) => {
                return (
                  <TextField label="Renseignements" multiline {...field} />
                );
              }}
              control={control}
            />

            <Card
              style={{
                marginTop: 20,
                padding: 20,
              }}
              variant="outlined"
            >
              <div
                style={{ marginTop: 0, marginBottom: 20, fontWeight: "bold" }}
              >
                Print
              </div>
              <Stack spacing={3}>
                <Controller
                  name="forPrint"
                  render={({ field }) => {
                    return (
                      <FormControlLabel
                        control={
                          <Switch
                            color="primary"
                            {...field}
                            checked={field.value}
                          />
                        }
                        label="Envoyer sur le print"
                        labelPlacement="end"
                        {...field}
                      />
                    );
                  }}
                  control={control}
                />
                <Controller
                  name="descriptionFormated"
                  render={({ field }) => {
                    return (
                      <TextField
                        label="Description Print"
                        {...field}
                        multiline
                        helperText={
                          field && field.value
                            ? `${field.value.length} caractères`
                            : ""
                        }
                      />
                    );
                  }}
                  control={control}
                />

                {editions !== null && (
                  <Controller
                    name="EditionId"
                    render={({ field }) => (
                      <TextField select label="Edition" {...field}>
                        {editions.map((e) => (
                          <MenuItem value={e.id}>{e.name}</MenuItem>
                        ))}
                      </TextField>
                    )}
                    control={control}
                  />
                )}
              </Stack>
            </Card>

            <Card style={{ marginTop: 20, padding: 20 }} variant="outlined">
              <div
                style={{ marginTop: 0, marginBottom: 20, fontWeight: "bold" }}
              >
                Ville
              </div>
              <Stack spacing={5}>
                <Controller
                  name="cityName"
                  render={({ field }) => {
                    return <TextField label="Ville" {...field} />;
                  }}
                  control={control}
                />
                <Controller
                  name="postalCode"
                  render={({ field }) => {
                    return <TextField label="Code postal" {...field} />;
                  }}
                  control={control}
                />
              </Stack>
            </Card>

            <Card style={{ marginTop: 20, padding: 20 }} variant="outlined">
              <div
                style={{ marginTop: 0, marginBottom: 20, fontWeight: "bold" }}
              >
                Image
              </div>
              <Stack spacing={5}>
                <Controller
                  name="image"
                  render={({ field }) => {
                    return (
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <TextField label="Lien" {...field} />
                        {field.value !== "" && field.value !== undefined && (
                          <img
                            style={{
                              maxWidth: "200px",
                              maxHeight: 150,
                              marginTop: 15,
                              borderRadius: 3,
                            }}
                            src={field.value}
                            alt="preview"
                          />
                        )}
                      </div>
                    );
                  }}
                  control={control}
                />
              </Stack>
            </Card>
            <Card style={{ marginTop: 20, padding: 20 }} variant="outlined">
              <div
                style={{ marginTop: 0, marginBottom: 20, fontWeight: "bold" }}
              >
                Dates
              </div>
              <Stack spacing={5}>
                <Controller
                  name="dates"
                  render={({ field }) => {
                    return (
                      <TextField
                        label="Ajout de dates"
                        value=""
                        {...field}
                        helperText={`Exemple : 19/04/2022,14/05/2022,12/03/2022-15/03/2022`}
                      />
                    );
                  }}
                  control={control}
                />
              </Stack>
            </Card>
            <Controller
              name="validated"
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <Switch color="primary" {...field} checked={field.value} />
                  }
                  label="Validé"
                  labelPlacement="end"
                  {...field}
                />
              )}
              control={control}
            />
            <Button
              type="submit"
              variant="contained"
              disabled={isSubmitting}
              style={{ maxWidth: 200 }}
            >
              Ajouter
            </Button>
          </Stack>
        </form>
      </Card>
    </div>
  );
};

export default DraftAdd;
