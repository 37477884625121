const login = ({ username, password }) => {
  if (
    (username === "florian@sedicom.fr" && password === "TempTest23!") ||
    (username === "clarisse@sedicom.fr" && password === "Clar23!pwd")
  ) {
    localStorage.setItem("username", username);

    return Promise.resolve();
  } else {
    return Promise.reject();
  }
};
const logout = () => {
  localStorage.removeItem("username");
  return Promise.resolve();
};
const checkError = ({ status }) => {
  if (status === 401 || status === 403) {
    localStorage.removeItem("username");
    return Promise.reject();
  }
  return Promise.resolve();
};
const checkAuth = () => {
  return localStorage.getItem("username")
    ? Promise.resolve()
    : Promise.reject();
};

const getPermissions = () => Promise.resolve();

const ex = {
  // called when the user attempts to log in
  login,
  // called when the user clicks on the logout button
  logout,
  // called when the API returns an error
  checkError,
  // called when the user navigates to a new location, to check for authentication
  checkAuth,
  // called when the user navigates to a new location, to check for permissions / roles
  getPermissions,
};
export default ex;
