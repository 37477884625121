import * as React from "react";
import { useRecordContext } from "react-admin";
import { useFluxs } from "../globalDataProvider";

const MyUrlField = ({ source }) => {
  const record = useRecordContext();
  const fluxs = useFluxs();
  if (fluxs === null) return null;
  const flux = fluxs.find((fl) => fl.id === parseInt(record[source]));
  console.log(flux);
  return record && flux ? `${flux.other}` : null;
};

export default MyUrlField;
