import React, { useState, useEffect } from "react";
import { fetchUtils } from "react-admin";
import { stringify } from "query-string";
import config from "./config";

const httpClient = fetchUtils.fetchJson;
function useEditions() {
  const [editions, setEditions] = useState(null);

  useEffect(() => {
    if (editions === null) {
      httpClient(`${config.apiUrl}/editions`).then(({ json }) => {
        setEditions(json);
      });
    }
  });

  return editions;
}

function useAgendaCategories() {
  const [agendaCategories, setAgendaCategories] = useState(null);

  useEffect(() => {
    if (agendaCategories === null) {
      httpClient(`${config.apiUrl}/agendacategories`).then(({ json }) => {
        setAgendaCategories(json);
      });
    }
  });

  return agendaCategories;
}
function useFluxs() {
  const [fluxs, setFluxs] = useState(null);

  useEffect(() => {
    if (fluxs === null) {
      httpClient(`${config.apiUrl}/fluxs`).then(({ json }) => {
        setFluxs(json);
      });
    }
  });

  return fluxs;
}
function useNbToExport() {
  const [nbToExport, setNbToExport] = useState(null);

  useEffect(() => {
    if (nbToExport === null) {
      httpClient(`${config.apiUrl}/nbtoexport`).then(({ json }) => {
        setNbToExport(json.count);
      });
    }
  });

  return nbToExport;
}

export { useEditions, useAgendaCategories, useFluxs, useNbToExport };
