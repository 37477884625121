import * as React from "react";
import { Card, CardContent, Button } from "@mui/material";
import { Title } from "react-admin";
import config from "./config.json";
import { useForm } from "react-hook-form";
import { useNbToExport } from "./globalDataProvider";

const Export = () => {
  const nbToExport = useNbToExport();
  return (
    <Card style={{ marginTop: 30 }}>
      <Title title="Import CSV" />
      <CardContent style={{ fontWeight: "bold" }}>
        Exporter sur le wordpress
      </CardContent>
      {nbToExport === null ? (
        <CardContent>Chargement...</CardContent>
      ) : (
        <CardContent>{nbToExport} Events validés en attente</CardContent>
      )}
      <CardContent>
        <Button
          type="submit"
          variant="contained"
          disabled={nbToExport === null || nbToExport === 0}
          onClick={() => {
            window.open(`https://api.jdp.lik.media/public/export`, "_blank");
          }}
        >
          Exporter
        </Button>
      </CardContent>
    </Card>
  );
};

export default Export;
