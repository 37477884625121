import * as React from "react";
import { Card, CardContent, Button } from "@mui/material";
import { Title } from "react-admin";
import config from "./config.json";
import { useForm } from "react-hook-form";

const Import = () => {
  const { register, handleSubmit } = useForm();

  const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append("file", data.file[0]);

    const res = await fetch(config.apiUrl + "/importcsv", {
      method: "POST",
      body: formData,
    }).then((res) => res.json());
    alert(JSON.stringify(`${res.message}, status: ${res.status}`));
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card style={{ marginTop: 30 }}>
        <Title title="Import CSV" />
        <CardContent style={{ fontWeight: "bold" }}>
          Importer un fichier CSV
        </CardContent>
        <CardContent>
          <input type="file" {...register("file")} required />
        </CardContent>
        <CardContent>
          <Button type="submit" variant="contained">
            Importer
          </Button>
        </CardContent>
      </Card>
    </form>
  );
};

export default Import;
